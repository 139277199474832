<script lang="ts" setup name="OrganisationSetting">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { Item, useBaseItems } from '~/features/useBaseItem';

import BaseItem from '~/components/BaseItem.vue';
import BaseToggle from '~/components/BaseToggle.vue';

import type { SettingColumn } from '~/types';

const props = defineProps<{
  modelValue: boolean;
  name: string;
  canEdit: boolean;
  subsetting?: boolean;
  columns?: SettingColumn[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const tooltip = !props.canEdit
  ? 'You are not authorised to enable settings. Please contact your admin for further information.'
  : undefined;

const inputValue = useVModel(props, 'modelValue', emit);
const row = computed(() =>
  props.columns?.length
    ? useBaseItems(
        ...props.columns.map((column) =>
          Item.label(column.label).value(column.value).preventTruncate(),
        ),
      )
    : null,
);
</script>

<template>
  <div
    class="relative flex w-full justify-between pr-6"
    :class="{
      'py-6 pl-6': !subsetting,
      'py-4 pl-16': subsetting,
      'hover:bg-charcoal-1': canEdit,
      'border-t border-charcoal-2 bg-charcoal-0': row && subsetting,
    }"
    :data-test="`organisation-${name}-setting`"
  >
    <button
      type="button"
      class="absolute inset-0 h-full w-full"
      :disabled="!canEdit"
      :class="canEdit ? 'cursor-pointer' : 'cursor-default'"
      @click="inputValue = !inputValue"
    />
    <div
      v-if="row"
      role="row"
      class="group grid w-full gap-y-1 md:grid-cols-2 md:gap-0"
    >
      <BaseItem v-for="cell in row" v-bind="cell" :key="cell.key" />
    </div>
    <p v-else>
      {{ name }}
    </p>
    <BaseToggle v-model="inputValue" :tooltip="tooltip" :disabled="!canEdit" />
  </div>
</template>
