import { ERROR_PRIORITIES } from '~/constants';

import type {
  CargoBookingPort,
  CargoPeriodType,
  ICargoBooking,
  ICargoBookingRow,
  ICargoErrorValidation,
  ICargoNominatedVessel,
  ICargoNominatedVesselRow,
  ICargoNominationAllocations,
  ICargoNominationAllocationsRow,
  IOption,
} from '~/types';

export const sortErrors = (errors: ICargoErrorValidation[]) => {
  return errors.sort((a, b) => {
    if (a.level === 'error' && b.level === 'warning') return -1;
    if (a.level === 'warning' && b.level === 'error') return 1;

    return ERROR_PRIORITIES[a.code] - ERROR_PRIORITIES[b.code];
  });
};

export const formatErrors = (
  errors?: ICargoErrorValidation[],
  cargoType?: CargoPeriodType,
) => {
  if (!errors) {
    return [];
  }

  const sortedErrors = sortErrors(errors);

  const formattedErrors = sortedErrors.map((error) => ({
    ...error,
    values: {
      ...error.values,
      period: cargoType,
    },
  }));

  return formattedErrors;
};

export const fillMissingPorts = (ports: CargoBookingPort[], total: number) => {
  const missingPorts = total - ports.length;

  for (let i = 0; i < missingPorts; i++) {
    ports.push({
      id: null,
      name: null,
      cargoBookingPortId: null,
      qty: null,
    });
  }

  return ports;
};

const formatOption = (option?: IOption | string | null) => {
  if (option === null || typeof option === 'string') {
    return { id: option, name: option };
  }

  return option;
};

const formatLoadingPort = (loadingPort: CargoBookingPort) => {
  return (
    loadingPort || {
      name: null,
      id: null,
      qty: null,
      cargoBookingPortId: null,
    }
  );
};

export const formatBookingRow = <T extends ICargoBooking>(
  bookings: T[],
  totalDischargePorts: number,
  cargoType?: CargoPeriodType,
) => {
  const newBookings = bookings.map(
    (booking) =>
      ({
        ...booking,
        dischargePorts: fillMissingPorts(
          booking.dischargePorts.map((port) => ({
            ...port,
            edited: true,
          })),
          totalDischargePorts,
        ),
        type: formatOption(booking.type),
        trade: formatOption(booking.trade),
        vesselVoyage: formatOption(booking.vesselVoyage),
        loadingPort: formatLoadingPort(booking.loadingPort),
        errors: formatErrors(booking.errors, cargoType),
        highlight: false,
        cancelled: booking.status === 'cancelled' || booking.cancelled,
      }) as ICargoBookingRow,
  );

  return newBookings;
};

export const formatBookingAllocationsRow = (
  bookings: ICargoNominationAllocations[],
  totalDischargePorts: number,
) => {
  const newBookings = bookings.map(
    (booking) =>
      ({
        ...booking,
        dischargePorts: fillMissingPorts(
          booking.dischargePorts.map((port) => ({
            ...port,
            edited: true,
          })),
          totalDischargePorts,
        ),
        type: formatOption(booking.type),
        trade: formatOption(booking.trade),
        highlight: false,
        cancelled: false,
        errors: [],
        changes: booking.newlyAdded
          ? [
              'code',
              'type.name',
              'trade.name',
              'laycanStart',
              'laycanFinish',
              'loadingPort.name',
              'dischargePorts.name',
              'dischargePorts.qty',
              'declared',
            ]
          : [],
      }) as ICargoNominationAllocationsRow,
  );

  return newBookings;
};

export const formatVesselRows = (
  vessels: ICargoNominatedVessel[],
  totalDischargePorts: number,
): ICargoNominatedVesselRow[] => {
  return vessels.map((vessel) => {
    return {
      ...vessel,
      type: formatOption(vessel.type),
      trade: formatOption(vessel.trade),
      dischargePorts: fillMissingPorts(
        vessel.dischargePorts.map((port) => ({
          ...port,
          edited: true,
        })),
        totalDischargePorts,
      ),
      errors: sortErrors(vessel.errors),
      highlight: false,
      cancelled: false,
      noOfBookings: vessel.bookings.length,
      editable: [
        'vesselVoyage',
        'type',
        'trade',
        'laycanStart',
        'laycanFinish',
        'eta',
        'loadingPort.name',
        'dischargePorts',
        'comment',
        'nominated',
      ],
    } as ICargoNominatedVesselRow;
  });
};
