import { reactive } from 'vue';

import { isEmpty } from '~/features/useUtilities';

import type {
  CargoBookingPreviewSettings,
  CargoBookingTransferError,
  ICargoBookingRow,
  ICargoBookingSettings,
  ICargoErrorValidation,
} from '~/types';

interface IState {
  selectedBooking: ICargoBookingRow | null;
  settings: ICargoBookingSettings | null;
  totalDischargePorts: number;
  importId: string | null;
  isUpdating: boolean;
  previewSettings: CargoBookingPreviewSettings | null;
  bookingTransferErrorMsg?: string | null;
  bookingTransferErrors?: CargoBookingTransferError[];
}

export const useCargoBookingBaseStore = () => {
  const state = reactive<IState>({
    selectedBooking: null,
    settings: null,
    importId: null,
    totalDischargePorts: 0,
    isUpdating: false,
    bookingTransferErrorMsg: null,
    bookingTransferErrors: [],
    previewSettings: null,
  });

  const isRowSelected = (id: string) => id === state.selectedBooking?.id;

  const selectBooking = (booking: ICargoBookingRow | null = null) => {
    state.selectedBooking = booking;
  };

  const unselectBooking = () => {
    state.selectedBooking = null;
  };

  const setSettings = (settings: ICargoBookingSettings) => {
    state.settings = settings;
  };

  const setPreviewSettings = (previewSettings: CargoBookingPreviewSettings) => {
    state.previewSettings = previewSettings;
  };

  const setTotalDischargePorts = (maxPorts: number) => {
    state.totalDischargePorts = maxPorts;
  };

  const incrementTotalDischargePorts = () => {
    state.totalDischargePorts += 1;
  };

  const setImportId = (id: string) => {
    state.importId = id;
  };

  const setIsUpdating = (updating: boolean) => {
    state.isUpdating = updating;
  };

  const setBookingTransferError = (
    bookingTransferErrors: CargoBookingTransferError[],
  ) => {
    if (isEmpty(bookingTransferErrors)) {
      state.bookingTransferErrorMsg = null;
      state.bookingTransferErrors = [];
      return;
    }
    state.bookingTransferErrors = [...bookingTransferErrors];
    const customerRefs = bookingTransferErrors.map(({ code }) => code);
    if (customerRefs.length === 1) {
      state.bookingTransferErrorMsg = `${customerRefs[0]} booking.`;
      return;
    }

    state.bookingTransferErrorMsg = customerRefs
      .map((customerRef, idx) => {
        if (idx === customerRefs.length - 1) {
          return ` and ${customerRef} bookings.`;
        }
        return `${idx !== 0 ? ', ' : ''}${customerRef}`;
      })
      .join('');
  };

  const calculateValidationErrors = (
    items: { errors: ICargoErrorValidation[] }[] | null,
  ): { errors: number; discrepancies: number } => {
    let discrepancies = 0;
    let errors = 0;

    if (!items || items.length === 0) {
      return {
        errors: 0,
        discrepancies: 0,
      };
    }

    for (const item of items) {
      for (const error of item.errors) {
        if (error.level === 'warning') discrepancies++;
        else if (error.level === 'error') errors++;
      }
    }

    return {
      discrepancies,
      errors,
    };
  };

  const reset = () => {
    state.importId = null;
    state.settings = null;
    state.selectedBooking = null;
    state.totalDischargePorts = 0;
    state.isUpdating = false;
    state.bookingTransferErrorMsg = null;
    state.bookingTransferErrors = [];
    state.previewSettings = null;
  };

  return {
    state,

    reset,
    setImportId,
    setSettings,
    setPreviewSettings,
    isRowSelected,
    selectBooking,
    unselectBooking,
    setTotalDischargePorts,
    incrementTotalDischargePorts,
    setIsUpdating,
    setBookingTransferError,
    calculateValidationErrors,
  };
};
