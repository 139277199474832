import type { CargoPeriodType, ICargoBookingRow, ICargoPeriod } from '~/types';

interface IBookingMap {
  booking: ICargoBookingRow;
  status: ICargoPeriod<ICargoBookingRow>;
}

interface IBookingToMove {
  oldBooking: IBookingMap;
  newBooking: ICargoBookingRow;
  newPeriodType: CargoPeriodType;
}
const _updateBooking = (
  oldBooking: ICargoBookingRow,
  newBooking: ICargoBookingRow,
) => {
  Object.assign(oldBooking, newBooking);
};

export const updatePeriodBookings = (
  oldPeriodsData: ICargoPeriod<ICargoBookingRow>[],
) => {
  const createBookingMap = () => {
    const map = new Map<string, IBookingMap>();

    oldPeriodsData.forEach((period) => {
      period.bookings.forEach((booking) => {
        map.set(booking.id, { booking, status: period });
      });
    });

    return map;
  };

  const moveBooking = ({
    oldBooking,
    newBooking,
    newPeriodType,
  }: IBookingToMove) => {
    oldBooking.status.bookings = oldBooking.status.bookings.filter(
      (b) => b.id !== oldBooking.booking.id,
    );

    const oldPeriodData = oldPeriodsData.find(
      (status) => status.type === newPeriodType,
    );

    if (oldPeriodData) {
      newBooking.highlight = true;
      oldPeriodData.bookings.push(newBooking);

      scheduleHighlightRemoval(newBooking, oldPeriodData);
    }
  };

  const scheduleHighlightRemoval = (
    highlightedBooking: ICargoBookingRow,
    periodData: ICargoPeriod<ICargoBookingRow>,
  ) => {
    const booking = periodData.bookings.find(
      (b) => b.id === highlightedBooking.id,
    );

    setTimeout(() => {
      if (booking) {
        booking.highlight = false;
      }
    }, 1500);
  };

  const moveOrUpdateBooking = ({
    oldBooking,
    newBooking,
    newPeriodType,
  }: IBookingToMove) => {
    const bookingTypeChanged = oldBooking.status.type !== newPeriodType;

    if (bookingTypeChanged) {
      moveBooking({ oldBooking, newBooking, newPeriodType });
      return;
    }

    _updateBooking(oldBooking.booking, newBooking);
  };

  const updateBookings = (newPeriodsData: ICargoPeriod<ICargoBookingRow>[]) => {
    const bookingMap = createBookingMap();

    newPeriodsData.forEach((newPeriod) => {
      const oldPeriodData = oldPeriodsData.find(
        (status) => status.type === newPeriod.type,
      );

      newPeriod.bookings.forEach((newBooking) => {
        const oldBooking = bookingMap.get(newBooking.id);

        if (oldBooking) {
          moveOrUpdateBooking({
            oldBooking,
            newBooking,
            newPeriodType: newPeriod.type,
          });
          return;
        }

        oldPeriodData?.bookings.push(newBooking);
      });
    });
  };

  return {
    updateBookings,
  };
};

export const updateBookings = (
  oldBookings: ICargoBookingRow[],
  newBookings: ICargoBookingRow[],
) => {
  newBookings.forEach((newBooking) => {
    const oldBooking = oldBookings.find(
      (booking) => booking.id === newBooking.id,
    );

    if (oldBooking) {
      _updateBooking(oldBooking, newBooking);
      return;
    }

    oldBookings.push(newBooking);
  });
};
