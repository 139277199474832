<script lang="ts" setup name="OrganisationSettingCollapsible">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import BaseToggle from '~/components/BaseToggle.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps<{
  modelValue: boolean;
  name: string;
  canEdit: boolean;
  subsetting?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const tooltip = !props.canEdit
  ? 'You are not authorised to enable settings. Please contact your admin for further information.'
  : undefined;

const inputValue = useVModel(props, 'modelValue', emit);
</script>

<template>
  <BaseCollapsible :closeOnLeave="!props.modelValue">
    <template #trigger="{ toggle, isOpen }">
      <button
        type="button"
        class="relative flex w-full justify-between px-6"
        :class="{
          'hover:bg-charcoal-1': canEdit,
          'py-4': subsetting,
          'py-6': !subsetting,
        }"
        @click="toggle"
        :disabled="disabled"
      >
        <div class="flex">
          <Icon
            icon="mdi:chevron-right"
            :class="{
              '-rotate-90': isOpen,
              'rotate-90': !isOpen,
              'text-charcoal-4 opacity-60': disabled,
              'text-primary-6': !disabled,
            }"
          />
          <p
            class="pl-4"
            :class="{
              'text-primary-6': isOpen,
            }"
          >
            {{ name }}
          </p>
        </div>

        <BaseToggle
          v-model="inputValue"
          :tooltip="tooltip"
          :disabled="!canEdit"
          @update:model-value="isOpen ? toggle() : null"
        />
      </button>
    </template>

    <slot />
  </BaseCollapsible>
</template>
