<script setup lang="ts" name="AdminOrganisationNewSettings">
import OrganisationSettings from '~/components/OrganisationSettings.vue';

import type { ActiveSetting, Setting } from '~/types';

const { settings } = defineProps<{
  settings: ActiveSetting[];
}>();

const emit = defineEmits<{
  (e: 'updateSettings', value: Setting[]): void;
}>();
</script>

<template>
  <OrganisationSettings
    :settings="settings"
    is-in-form
    @update-settings="emit('updateSettings', $event)"
  >
    <div class="base-page-grid-gap pb-4">
      <p class="text-subtitle-1 text-charcoal-9">Manage Settings</p>

      <p>
        Manage access to the specific pages. Only enabled sections will be
        displayed to Organisation users.
      </p>
    </div>
  </OrganisationSettings>
</template>
